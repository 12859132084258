import { interpret } from 'xstate';
import {
  sidebarMachine,
  EventType,
  SidebarView,
  SidebarPosition,
} from 'xstate/machines/SidebarMachine';

export const SidebarService = interpret(sidebarMachine);
SidebarService.start();

export const reset = (): void => {
  SidebarService.send({ type: EventType.RequestReset });
};

export const closeSidebar = (): void => {
  SidebarService.send({ type: EventType.RequestClose });
};

export const openBoxSidebar = (): void => {
  SidebarService.send(EventType.RequestOpen, {
    view: SidebarView.Box,
    position: SidebarPosition.Left,
  });
};

export const openCollectionSidebar = (): void => {
  SidebarService.send(EventType.RequestOpen, {
    view: SidebarView.Collection,
    position: SidebarPosition.Right,
  });
};

export const openSettingsSidebar = (): void => {
  SidebarService.send(EventType.RequestOpen, {
    view: SidebarView.Settings,
    position: SidebarPosition.Left,
  });
};
