import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../translations/en';
import fr from '../translations/fr';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React escapes by default
      format: (value: any, format?: string, lng?: string) => {
        if (format === 'currency' && typeof value === 'object') {
          return new Intl.NumberFormat(lng, {
            style: 'currency',
            currency: value.currency,
          }).format(value.amount);
        }
        return value;
      },
    },
    resources: {
      en, // All English translations
      fr, // All French translations
    },
    ns: ['common', 'settings'], // Namespaces
    defaultNS: 'common', // Default namespace
  });

export default i18n;
