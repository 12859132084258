import {
  BaseActionObject,
  interpret,
  Interpreter,
  ResolveTypegenMeta,
  ServiceMap,
  State,
  TypegenDisabled,
} from 'xstate';
import {
  boxMachine,
  BoxMachineContext,
  BoxMachineEvent,
  BoxMachineState,
  EventType,
  StateValue,
} from '../machines/BoxMachine';
import { Box } from 'models/Box';
import { Collection } from 'models/Collection';

type BoxService = Interpreter<
  BoxMachineContext,
  any,
  BoxMachineEvent,
  BoxMachineState,
  ResolveTypegenMeta<
    TypegenDisabled,
    BoxMachineEvent,
    BaseActionObject,
    ServiceMap
  >
>;

let boxService: BoxService;

export const getBoxService = () => {
  if (!boxService) {
    boxService = interpret(boxMachine)
      .onTransition((state) => {
        if (state.changed) {
          // console.log("State Change: ", state);
          // console.log("Context:", state.context);
          // console.log("Value:", state.value);
        }
      })
      .start();
  }
  return boxService;
};

// Helpers

export const reset = (): void => {
  boxService.send({ type: EventType.RequestToResetBox });
};

export const requestToCheckSelection = (): void => {
  boxService.send(EventType.RequestToCheckSelection);
};

export const requestToSelectBox = (box: Box): void => {
  boxService.send(EventType.RequestToSelectBox, {
    box: box,
  });
};

export const requestToSelectCollection = (collection: Collection): void => {
  boxService.send(EventType.RequestToSelectCollection, {
    collection: collection,
  });
};

export const requestToResetBox = (): void => {
  boxService.send(EventType.RequestToResetBox);
};

export const requestToResetCollection = (): void => {
  boxService.send(EventType.RequestToResetCollection);
};

// Selectors

export const Selectors = {
  selectedBox: (
    state: State<BoxMachineContext, any, any, BoxMachineState>
  ): Box | undefined => state.context.selectedBox,
  selectedCollection: (
    state: State<BoxMachineContext, any, any, BoxMachineState>
  ): Collection | undefined => state.context.selectedCollection,
  isCheckingSelection: (
    state: State<BoxMachineContext, any, any, BoxMachineState>
  ): boolean => state.matches(StateValue.CheckingSelection),
  isSelected: (
    state: State<BoxMachineContext, any, any, BoxMachineState>
  ): boolean => state.matches(StateValue.BoxSelected),
  isUnselected: (
    state: State<BoxMachineContext, any, any, BoxMachineState>
  ): boolean => state.matches(StateValue.Unselected),
};
