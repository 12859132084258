import { revenueCat as config } from 'config/global';
import { useSubscriptionInfo } from './useSubscriptionInfo';
import { Subscription } from '../services/revenueCat';

interface ProStatus {
  isPro: boolean;
  subscription: Subscription | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
}

export const useProStatus = (): ProStatus => {
  const { data: info, isLoading, isError, error } = useSubscriptionInfo();

  const subscriptions = info?.subscriber?.subscriptions || {};
  const entitlements = info?.subscriber?.entitlements || {};

  // Check if the user has the required entitlement
  const entitlement = entitlements[config.entitlementId];
  const subscription = entitlement
    ? subscriptions[entitlement.productIdentifier]
    : null;

  // Determine if the subscription is active and valid
  const isActive =
    subscription?.expiresDate &&
    new Date(subscription.expiresDate) > new Date();
  const isPro = !!subscription && isActive;

  return {
    isPro: isPro,
    subscription: subscription,
    isLoading,
    isError,
    error,
  };
};
