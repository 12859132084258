import { QueryClient } from 'react-query';

export const queryClient = new QueryClient();

export const clear = (): void => {
  // Clear all connected caches
  queryClient.clear();
};

export enum QueryKey {
  /**
   * The `BOXES` query is used to fetch all memory boxes belonging to the authenticated
   * user.
   */
  BOXES = 'boxes',

  /**
   * The `COLLECTIONS` query is used to fetch all collections belonging to the
   * authenticated user.
   */
  COLLECTIONS = 'collections',

  /**
   * The `DROPS` query is used to fetch all drops belonging to the authenticated
   * user.
   */
  DROPS = 'drops',

  /**
   * The `OPEN_GRAPH_SCRAPER` query is used to fetch open graph data for URLs.
   */
  OPEN_GRAPH_SCRAPER = 'open-graph-scraper',

  /**
   * The `USER_ACCOUNT` query is used to fetch the account belonging to the
   * authenticated user.
   */
  USER_ACCOUNT = 'user-account',

  /**
   * The `LOCALISATION` query is used to fetch the localisation based on the IP
   * address.
   */
  LOCALISATION = 'localisation',

  /**
   * The `BILLING_CATALOG` query is used to fetch the product catalog setup
   * in Stripe (obtained from our backend).
   */
  BILLING_CATALOG = 'billing-catalog',

  /**
   * The `BILLING_SESSIONS` query is used to fetch sessions
   */
  BILLING_SESSIONS = 'billing-sessions',

  /**
   * The `BILLING_INVOICES` query is used to fetch invoices
   */
  BILLING_INVOICES = 'billing-invoices',

  /**
   * The `BILLING_SUBSCRIPTIONS` query is used to fetch subscriptions
   */
  BILLING_SUBSCRIPTIONS = 'billing-subscriptions',

  /**
   * The `BILLING_PAYMENT_METHOD` query is used to fetch the default payment method
   */
  BILLING_PAYMENT_METHOD = 'billing-payment-method',
}

export enum MutationKey {
  /**
   * The `BILLING_CREATE_SESSION` mutation is used to create a Stripe Checkout Session.
   */
  BILLING_CREATE_SESSION = 'billing-create-session',
}
