/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import type { AppProps /*, AppContext */ } from 'next/app';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'styles/globals.css';
import useAuthenticationObserver from 'hooks/useAuthenticationObserver';
import { QueryClientProvider } from 'react-query';
import { BackgroundColor } from 'utils/ColorUtils';
import RouteProtector from 'hocs/RouteProtector';
import { queryClient } from 'services/queryClient';
import { SegmentHOC } from 'tags/SegmentTag';
import Modal from 'elements/Modal';
import SlideOver from 'elements/SlideOver';
import Notifications from 'elements/Notifications';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import growthbook from 'services/growthbook';

// Font Awesome
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import SidebarDialog from 'elements/Sidebar/SidebarDialog';
import i18n from 'services/i18n';
import { I18nextProvider } from 'react-i18next';
config.autoAddCss = false;

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  backgroundColor?: BackgroundColor; // Maybe a Theme soon?
  isHeaderHidden?: boolean;
  isHeaderTransparent?: boolean;
  isFooterHidden?: boolean;
  isFooterTransparent?: boolean;

  // We then pass the above to the layout
  getLayout?: (
    page: ReactElement,
    backgrounColor?: BackgroundColor,
    isHeaderHidden?: boolean,
    isHeaderTransparent?: boolean,
    isFooterHidden?: boolean,
    isFooterTransparent?: boolean
  ) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  // @ts-ignore
  // getLayout is used to introduce Persistent Layout
  // Ref. https://nextjs.org/docs/basic-features/layouts
  // Ref. https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  const getLayout = Component.getLayout || ((page) => page);

  const backgroundColor = Component.backgroundColor || BackgroundColor.White;
  const isHeaderHidden = Component.isHeaderHidden || false;
  const isHeaderTransparent = Component.isHeaderTransparent || false;
  const isFooterHidden = Component.isFooterHidden || false;
  const isFooterTransparent = Component.isFooterTransparent || false;

  useAuthenticationObserver();

  useEffect(() => {
    // We don't load features as soon as possible - we load features asynchronously when the app renders.
    // Until features are loaded, all features will evaluate to null. We're ok with a potential flicker
    // in our application (features going from null to their real value)
    growthbook.loadFeatures();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        {getLayout(
          <GrowthBookProvider growthbook={growthbook}>
            <SegmentHOC>
              <RouteProtector router={router}>
                <ParallaxProvider>
                  <Component {...pageProps} />
                  <Modal />
                  <SlideOver />
                  <SidebarDialog />
                  <Notifications />
                </ParallaxProvider>
              </RouteProtector>
            </SegmentHOC>
          </GrowthBookProvider>,
          backgroundColor,
          isHeaderHidden,
          isHeaderTransparent,
          isFooterHidden,
          isFooterTransparent
        )}
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
