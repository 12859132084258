import { interpret } from 'xstate';
import {
  slideOverMachine,
  BoxSlideOverPayload,
  CollectionSlideOverPayload,
  EventType,
  SlideOverView,
} from 'machines/SlideOverMachine';
import type { Collection } from 'models/Collection';
import type { Box } from 'models/Box';

export const SlideOverService = interpret(slideOverMachine);
SlideOverService.start();

export const reset = (): void => {
  SlideOverService.send({ type: EventType.RequestReset });
};

export const closeSlideOver = (): void => {
  SlideOverService.send({ type: EventType.RequestClose });
};

export const openCollectionSlideOver = (collection?: Collection): void => {
  const payload: CollectionSlideOverPayload = {
    collection,
  };

  SlideOverService.send(EventType.RequestOpen, {
    view: SlideOverView.Collection,
    payload,
  });
};

export const openBoxSlideOver = (box?: Box): void => {
  const payload: BoxSlideOverPayload = {
    box,
  };

  SlideOverService.send(EventType.RequestOpen, {
    view: SlideOverView.Box,
    payload,
  });
};
