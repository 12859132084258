import { Sidebar, SidebarBody, SidebarHeader, SidebarSection } from './Sidebar';
import { useAuthentication } from 'hooks/useAuthentication';
import SettingsMenu from 'templates/Settings/Nav/SettingsMenu';
import Avatar from 'elements/Avatar';
import Link from 'next/link';
import { appRoutes } from 'config/appRoutes';
import { CloseButton as ClosableLink } from '@headlessui/react';
import { useProStatus } from 'networking/revenueCat/hooks/useProStatus';

const SettingsSidebar = () => {
  const { authenticatedUser } = useAuthentication();
  const { isPro } = useProStatus();

  return (
    <Sidebar>
      <SidebarHeader className="border-none pb-0">
        <ClosableLink
          as={Link}
          href={appRoutes.MEMORY_BOX}
          className={styles.header.container}
        >
          <div className={styles.header.left}>
            <Avatar user={authenticatedUser} withGoldRing={isPro} />
          </div>

          <div className={styles.header.right.container}>
            <span className={styles.header.right.top}>
              {authenticatedUser?.getDisplayName() ?? 'Display Name'}
            </span>
            <span className={styles.header.right.bottom}>
              {authenticatedUser?.getUsername() ?? '@username'}
            </span>
          </div>
        </ClosableLink>
      </SidebarHeader>

      <SidebarBody className="pt-0">
        <SidebarSection>
          <SettingsMenu />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};

export default SettingsSidebar;

const styles = {
  header: {
    container:
      'flex flex-row border-none hover:bg-[#F4F4F4] rounded-lg p-2 mb-2',
    left: 'pl-2 pr-4 py-2',
    right: {
      container: 'flex-1',
      top: 'block font-semibold',
      bottom: 'text-gray-500 font-normal',
    },
  },
};
